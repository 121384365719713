<template>
  <v-menu id="language-menu">
    <template #button>
      <v-button
          rounded="circle"
          :title="languages[language]"
          icon
          class="language-button"
      >
        <span
            class="fi fis icon-language"
            :class="getFlagClassByLanguage(language)"
        />
      </v-button>
    </template>

    <r-focus-trap>
      <v-list table>
        <v-list-item
          :key="index"
          @click="setLanguage({language: value, reload: true})"
          @keyup.enter="setLanguage({language: value, reload: true})"
          clickable
          hoverable
          noIndent
          tabindex="0"
          v-for="(label, value, index) in languages"
        >
          <template #start>
          <span
            class="fi fis icon-language m-8"
            :class="getFlagClassByLanguage(value)"
          />
          </template>

          <template #default>
            {{ label }}
          </template>

          <template #end>
            <v-icon
              class="m-8"
              v-if="language === value"
              stroke="primary"
              name="checked"
              size="12"
            />
          </template>
        </v-list-item>
      </v-list>
    </r-focus-trap>
  </v-menu>
</template>

<script>
import { VButton, VIcon, VList, VListItem, VMenu } from '@components/base'
import 'flag-icons/css/flag-icons.min.css'
import { RFocusTrap } from '@components/rednderless'

export default {
  name: 'VLanguageSelect',

  components: {
    RFocusTrap,
    VButton,
    VList,
    VIcon,
    VListItem,
    VMenu
  },

  props: {
    language: String,
    languages: Object
  },

  methods: {
    setLanguage (language) {
      this.$emit('setLanguage', language)
    },
    getFlagClassByLanguage (language) {
      return `fi-${language?.slice(-2, language.length)?.toLowerCase() || 'xx'}`
    }
  }
}
</script>

<style lang="scss">
@use "sass:map";
@import "~@styles/variables/index.scss";
@import "~@styles/tools/index.scss";

.icon-language {
  &.fi {
    width: 1.4rem;
    height: 1.4rem;
    @include radius(pill);
    border: 1px solid map.get($theme-colors, "border");
  }
}

.language-button {
  width: 1.4rem;
  height: 1.4rem;
  background-color: transparent;
}
</style>
