<template>
  <div class="container py-8">
    <div class="row flex-nowrap align-items-center">
      <div
        v-if="global.logo.url"
        class="col-auto"
      >
        <img
          :src="global.logo.url"
          :alt="global.logo.alt"
          class="logo__img"
          draggable="false"
        />
      </div>

      <div class="col"></div>

      <div
        v-if="supportedLanguageShow"
        class="col-auto"
      >
        <v-language-select
          :language="language.current"
          :languages="supportedLanguageHash"
          @setLanguage="setLanguage"
        />
      </div>

      <div
        v-if="showUserName"
        class="col-auto user-info"
      >
        <v-icon-svg
          class="mr-8 svg-fill-primary svg-stroke-primary"
          view-box="0 0 20 24"
          title="svg-icon-user"
          width="20"
          height="20"
        >
          <svg-icon-user/>
        </v-icon-svg>

        {{ global.user.displayName }}
      </div>
    </div>
  </div>
</template>

<script>
import SvgIconUser from '@components/icons/SvgIconUser'
import { VIconSvg } from '@components/base'
import { mapActions, mapGetters, mapState } from 'vuex'
import VLanguageSelect from '@components/base/VLanguageSelect/VLanguageSelect.vue'

export default {
  name: 'AppHeader',

  components: {
    VLanguageSelect,
    SvgIconUser,
    VIconSvg
  },

  inject: ['global'],

  computed: {
    ...mapState({
      theme: state => state.theme,
      language: state => state.language,
      currentParams: state => state.theme.params
    }),

    ...mapGetters({
      supportedLanguageHash: 'language/supportedHash',
      supportedLanguageShow: 'language/supportedShow'
    }),

    showUserName () {
      return this.global.user.displayName &&
        this.currentParams.find(obj => obj.name === 'fio').visible
    }
  },

  methods: {
    ...mapActions({
      setLanguage: 'language/setCurrent'
    })
  }
}
</script>

<style lang="scss" scoped>
.lang-img-icon {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 1px solid var(--color-border)
}

.logo {
  display: flex;
  text-decoration: none;
  user-select: none;

  &__img {
    display: block;
    width: auto;
    height: 2rem;
    padding: 0;
    margin: 0
  }
}

.user-info {
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
