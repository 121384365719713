import Vue from 'vue'
import VueRouter from 'vue-router'
import { routerMain } from './routes'

const { BASE_URL } = process.env

Vue.use(VueRouter)

const routes = [
  ...routerMain,
  {
    path: '/',
    name: 'WarningPage',
    component: () => import('@/views/Warnings/WarningRoute')
  },
  {
    path: '/*',
    name: 'Processing',
    component: () => import('@/views/Processing/Processing')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: BASE_URL,
  routes: routes
})

export default router
