<template>
  <hr
      :aria-orientation="orientation"
      :class="classes"
      class="v-divider"
      role="separator"
  />
</template>

<script>
import { DIVIDER_THEMES } from '@constants/components'
import { Colorable } from '@mixins'

export default {
  name: 'VDivider',

  mixins: [Colorable],

  props: {
    inset: Boolean,
    theme: {
      type: String,
      validator: (theme) => DIVIDER_THEMES.includes(theme)
    },
    vertical: Boolean
  },

  computed: {
    orientation () {
      return this.vertical
        ? 'vertical'
        : 'horizontal'
    },
    classes () {
      return {
        'v-divider_type_inset': this.inset,
        'v-divider_type_vertical': this.vertical,
        [`v-divider_theme_${this.theme}`]: this.theme,
        ...this.colorClasses
      }
    }
  }
}
</script>

<style lang="scss">
@use "sass:map";
@import "~@styles/variables";
@import "~@styles/tools";

$divider-inset-margin: 72px !default;
$divider-inset-margin-top: 8px !default;
$divider-inset-max-height: calc(100% - 16px) !default;

.v-divider {
  background-color: transparent;
  display: block;
  flex: 1 1 0;
  max-width: 100%;
  height: 0;
  max-height: 0;
  margin: 0;
  border: 0 solid cl(border);
  border-top-width: thin;
  transition: inherit;

  &_type {
    &_inset {
      &:not(.v-divider_type_vertical) {
        max-width: calc(100% - #{$divider-inset-margin})
      }
    }

    &_vertical {
      align-self: stretch;
      border: 0 solid cl(border);
      border-right-width: thin;
      display: inline-flex;
      height: inherit;
      min-height: 100%;
      max-height: 100%;
      max-width: 0;
      width: 0;
      vertical-align: text-bottom;
      margin: 0 -1px; // Prevent wrapping in flex layouts

      &.v-divider_type_inset {
        margin-top: $divider-inset-margin-top;
        min-height: 0;
        max-height: $divider-inset-max-height;
      }
    }
  }

  &_color {
    @each $color in map.keys($theme-colors) {
      &_#{$color} {
        border-color: cl($color);
      }
    }
  }

  &_theme {}
}
</style>
