<template>
  <div
    :class="classes"
    aria-hidden="true"
    class="v-icon"
  >
    <slot>
      <svg>
        <use :xlink:href="url"/>
      </svg>
    </slot>
  </div>
</template>

<script>
import { Sizeable } from '@mixins'
import * as sprite from '@components/base/VIcon/sprites'
import { COLORS, ICONS_SPRITE_NAMES } from '@constants/components'

export default {
  name: 'VIcon',

  mixins: [
    Sizeable
  ],

  props: {
    name: String,
    fill: {
      type: String,
      validator: color => COLORS.includes(color)
    },
    stroke: {
      type: String,
      validator: color => COLORS.includes(color)
    },
    spriteName: {
      type: String,
      validator: spriteName => ICONS_SPRITE_NAMES.includes(spriteName),
      default: 'common'
    }
  },

  computed: {
    url () {
      return `${sprite[this.spriteName]}#${this.name}`
    },
    classes () {
      return {
        [`v-icon_fill_${this.fill}`]: !!this.fill,
        [`v-icon_stroke_${this.stroke}`]: !!this.stroke,
        ...this.sizeClasses
      }
    }
  }
}
</script>

<style lang="scss">
@use "sass:map";
@import "~@styles/variables";
@import "~@styles/tools";

.v-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 1.5rem;
  height: 1.5rem;
  fill: none;
  stroke: none;

  > svg {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }

  &_size {
    @each $el-size in map.keys($spacers) {
      &_#{$el-size} {
        width: map-get($spacers, $el-size);
        height: map-get($spacers, $el-size);

        > svg {
          width: map-get($spacers, $el-size);
          height: map-get($spacers, $el-size);
        }
      }
    }
  }

  &_fill {
    @each $color in map.keys($theme-colors) {
      &_#{$color} {
        fill: cl($color);
      }
    }
  }

  &_stroke {
    @each $color in map.keys($theme-colors) {
      &_#{$color} {
        stroke: cl($color);
      }
    }
  }
}
</style>
