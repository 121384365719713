import axios from 'axios'
import router from '@/router'
import { ShowMessageOnTimer } from '@/services'
import { i18n } from '@/i18n'
import { store } from '@/store'
import vueInstance from '@/main'
import { getUrlParams } from '@utils/helpers'

const { VUE_APP_API_URL } = process.env
const apiAxios = axios.create({
  baseURL: `${VUE_APP_API_URL}`
})
// Уведомление пользователя в случае долгого соединения
const showMessageOnTimer = new ShowMessageOnTimer()
const setMessage = (message = i18n.t('site.message.goToTestLink')) => {
  vueInstance.error.message = message
}

apiAxios.interceptors.request.use(config => {
  const token = localStorage.getItem('x-auth-token')
  const urlParams = getUrlParams()

  config.headers.Authorization = token
    ? `Bearer ${token}`
    : ''
  showMessageOnTimer.start()

  if (config.url === 'content' && store.state.language.current) {
    config.headers['Content-Language'] = store.state.language.current
  } else {
    delete config.headers['Content-Language']
  }

  if (config.url === 'session') {
    /**
     * добавление utm меток
     * @link https://ecopsy.atlassian.net/wiki/spaces/LKS/pages/2206531593/-#UTM-%D0%BC%D0%B5%D1%82%D0%BA%D0%B8
     */

    Object.entries(urlParams).forEach(([key, value]) => {
      const whiteList = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

      if (whiteList.includes(key)) {
        config.data.append(key, value)
      }
    })
  }

  return config
})

apiAxios.interceptors.response.use(
  (response) => {
    showMessageOnTimer.stop()

    // Меняем язык только если данные из /content
    if (response.config.url === 'content') {
      store
        .dispatch('language/setCurrent', { language: response.headers['content-language'] })
        .then(() => showMessageOnTimer.setMessage(i18n.t('site.error.request.internetConnectionIsUnstable')))
        .then(() => setMessage())
    }

    return response
  },
  (error) => {
    showMessageOnTimer.stop()

    // зачистка предыдущей ошибки
    setMessage()

    if (error?.response) {
      const { status, data, config } = error.response

      if (config.url === 'content') {
        setMessage(data.message || i18n.t('site.error.request.onServer'))

        if (router.currentRoute.path !== '/') {
          return router
            .push('/')
            .then(() => Promise.reject(error))
        }
      } else if (
        data?.message &&
        status === 500
      ) {
        store.dispatch('snackbar/showSnackbar', {
          content: data.message,
          options: { color: 'danger' }
        })
        console.error(data.message)
      }
    }

    return Promise.reject(error)
  }
)

// processing
export const getContentApi = data => apiAxios.post('content', data)
export const sendContentApi = data => apiAxios.post('response', data)

// check
export const sendTelemetryApi = data => apiAxios.post('telemetry', data)
export const sendCheckApi = data => apiAxios.post('check', data)

// for dynamic logic
export const sendAnswersAndGetDynamicContent = (data, options) => apiAxios({
  url: 'nsadc',
  method: 'post',
  ...options,
  data
})

export default {
  getContentApi,
  sendContentApi,

  sendTelemetryApi,

  sendAnswersAndGetDynamicContent
}
