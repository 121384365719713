<template>
  <section class="container">
    <div class="row align-items-center footer-content py-24">
      <div class="col-md-12 col-lg">
        <span v-if="visiblePhone || visibleEmail">{{ $t('site.footer.contactUs') }}:</span>

        <span class="footer-content__contacts">
            <template v-if="phoneNumber && visiblePhone">
              <a
                  :href="`tel:${phoneNumber}`"
                  class="link-shadow"
              >
                {{ phoneNumber | toPhoneNumber }}
              </a>

              &nbsp;<span
                aria-hidden="true"
                class="footer-content-separator"
                v-if="visibleEmail"
            >|</span>&nbsp;
            </template>
            <a
                :href="`mailto:${emailValue}`"
                class="link-underline"
                v-if="visibleEmail"
            >
              {{ emailValue }}
            </a>
          </span>
      </div>

      <div class="col-lg-auto footer-content__copyright text-size-14">
        {{ copyright }}
      </div>
    </div>
  </section>
</template>

<script>
import toPhoneNumber from '@filters/toPhoneNumber'
import { mapState } from 'vuex'

export default {
  name: 'AppFooter',

  filters: {
    toPhoneNumber
  },

  props: {
    copyright: String
  },

  computed: {
    ...mapState({
      currentTheme: state => state.theme.current,
      currentParams: state => state.theme.params
    }),

    phoneNumber () {
      return this.currentParams.find(obj => obj.name === 'phone').value
    },
    emailValue () {
      return this.currentParams.find(obj => obj.name === 'email').value
    },
    visibleEmail () {
      return this.currentParams.find(obj => obj.name === 'email').visible
    },
    visiblePhone () {
      return this.currentParams.find(obj => obj.name === 'phone').visible
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@assets/styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.footer-content {
  font-size: .75rem;

  @include media-breakpoint-only(md) {
    text-align: center;
  }

  &__contacts {
    padding-left: 1rem;

    @include media-breakpoint-down(sm) {
      display: block;
      margin-top: 1rem;
      padding-left: 0;
    }
  }

  &__copyright {
    @include media-breakpoint-down(md) {
      margin-top: 1rem;
    }
  }
}

.footer-content-separator {
  color: #5c5c5c;
}
</style>
